import {
  UPDATE_ERROR_FIELD,
  UPDATE_FIELD,
  LOGIN_CONFIRMATION,
  USER_LOGIN,
  UPDATE_PASSCODE,
  SHOW_CONNECT_OPTIONS,
  CLEAR_SHOW_CONNECT_OPTIONS,
  SHOW_SOUNDCLOUD_MODAL,
  CLEAR_SHOW_SOUNDCLOUD_MODAL
} from '../actions/Login/loginAction';

import { UPDATE_FACEBOOK, UPDATE_INSTAGRAM, UPDATE_DEEZER } from '../actions/Social/socialAction';

import {
  UPDATE_INIT,
  UPDATE_GENRE,
  UPDATE_LOCATION
} from '../actions/Profile/profileAction';

import {
  UPDATE_PROFILE,
  UPDATE_PROFILE_FAILED,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAILED,
  RESET_UPDATE_PASSWORD
} from '../actions/settings/settingsAction';

const initialState = {
  username: '',
  usernameErr: '',
  password: '',
  passwordErr: '',
  cPassword: '',
  account: '',
  lastName: '',
  firstName: '',
  company: '',
  street1: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
  genre: '',
  goal: '',
  confirmation: '',
  admin: false,
  passcode: '',
  twitter: {},
  facebook: {},
  instagram: {},
  init: 99,
  artistId: ''
};

function user(state = initialState, action) {
  switch (action.type) {
    case 'INIT_DATA':
    {
      return action.data;
    }
    case 'USER_LOGOUT':
    {
      let newState = Object.assign({}, state);
      newState = mapUser(newState, null);
      return newState;
    }
    case USER_LOGIN:
    {
      // console.log('USER_LOGIN');
      // console.log('action:',action);
      // console.log('brand:', action.brand);
      let newState = Object.assign({}, state);
      if (action.user) {
        newState = mapUser(newState, action.user);
        newState.password = '';
        newState.cPassword = '';
        if (action.user.facebook && action.user.facebook.reconnect !== undefined) {
          if (window.localStorage.getItem('facebook.reconnect') !== 'done') {
            window.localStorage.setItem('facebook.reconnect', action.user.facebook.reconnect);
          }
        }
        if (action.user.instagram && action.user.instagram.reconnect !== undefined) {
          if (window.localStorage.getItem('instagram.reconnect') !== 'done') {
            window.localStorage.setItem('instagram.reconnect', action.user.instagram.reconnect);
          }
        }
        if (action.user.soundCloud && action.user.soundCloud.reconnect !== undefined) {
          if (window.localStorage.getItem('soundcloud.reconnect') !== 'done') {
            window.localStorage.setItem('soundcloud.reconnect', action.user.soundCloud.reconnect);
          }
        }
      }
      if (action.err) {
        newState.errMessage = action.err.errorMessage;
      } else {
        newState.errMessage = null;
      }

      return newState;
    }
    case UPDATE_ERROR_FIELD:
    {
      let newState = Object.assign({}, state);
      newState[action.field + 'Err'] = action.value;
      return newState;
    }
    case UPDATE_FIELD:
    {
      let newState = Object.assign({}, state);
      newState[action.field] = action.value;
      return newState;
    }
    case LOGIN_CONFIRMATION:
    {
      let newState = Object.assign({}, state);
      newState.confirmation = action.value;
      newState.showConnectOptions = null;
      newState.showSoundCloudModal = null;
      return newState;
    }
    case UPDATE_INIT:
    {
      let newState = Object.assign({}, state);
      newState.init = action.value;
      return newState;
    }
    case UPDATE_GENRE:
    {
      let newState = Object.assign({}, state);
      newState.init = action.value;
      return newState;
    }
    case UPDATE_LOCATION:
    {
      let newState = Object.assign({}, state);
      newState.city = action.city;
      newState.state = action.state;
      newState.zip = action.zip;
      return newState;
    }
    case UPDATE_PASSCODE:
    {
      let newState = Object.assign({}, state);
      newState.passcode = action.passcode;
      return newState
    }
    case UPDATE_FACEBOOK:
    {
      let newState = Object.assign({}, state);
      newState = updateUser(newState, action.data);
      return newState;
    }
    case UPDATE_DEEZER: {
      let newState = Object.assign({}, state);
      console.log('----action.data', action.data);
      newState = updateUser(newState, action.data);
      console.log('----action.errMessage',action.errMessage);
      if (action.errMessage) {
        newState.errMessage = action.errMessage;
      }
      return newState;
    }
    case UPDATE_INSTAGRAM:
    {
      let newState = Object.assign({}, state);
      newState = updateUser(newState, action.data);
      return newState;
    }
    case 'USER_UPDATE':
    {
      // console.log('-=-=-=-=-=-=-');
      // console.log('---action.user:',action.user);
      let newState = Object.assign({}, state);
      if (action.user) {
        newState = mapUser(newState, action.user);
      }
      if (action.err) {
        newState.errMessage = action.err.errorMessage;
      } else {
        newState.errMessage = null;
      }
      // console.log('newState', newState);
      return newState;
    }
    case SHOW_CONNECT_OPTIONS: {
      let newState = Object.assign({}, state);
      if (action.value) {
        newState.showConnectOptions = action.value
      }
      return newState;
    }
    case CLEAR_SHOW_CONNECT_OPTIONS: {
      let newState = Object.assign({}, state);
      newState.showConnectOptions = null;
      return newState;
    }
    case SHOW_SOUNDCLOUD_MODAL: {
      let newState = Object.assign({}, state);
      newState.showSoundCloudModal = true;
      return newState;
    }
    case CLEAR_SHOW_SOUNDCLOUD_MODAL: {
      let newState = Object.assign({}, state);
      newState.showSoundCloudModal = null;
      return newState;
    }


    case UPDATE_PROFILE: {
      let newState = Object.assign({}, state);
      newState = mapProfile(newState, action.profile);
      newState.updateSuccessful = true;

      // console.log('---- in UPDATE_PROFILE settings newState:', newState);
      return newState;
    }
    case UPDATE_PROFILE_FAILED: {
      let newState = Object.assign({}, state);
      newState.updateError = action.err;

      // console.log('---- in UPDATE_PROFILE_FAILED settings newState:', newState);
      return newState;
    }

    case UPDATE_PASSWORD: {
      let newState = Object.assign({}, state);
      newState.updatePasswordSuccessful = true;

      return newState;
    }
    case UPDATE_PASSWORD_FAILED: {
      let newState = Object.assign({}, state);
      newState.updatePasswordError = action.err;
      return newState;
    }

    case RESET_UPDATE_PASSWORD: {
      let newState = Object.assign({}, state);
      newState.updatePasswordSuccessful = false;

      return newState;
    }

    default:
    {
      return state;
    }
  }
}

const updateUser = (newState, data) => {
  if (!data) {
    return newState;
  }
  if (data.facebook) {
    let { id, email, name, link } = data.facebook;
    let facebook = {};
    facebook.id = id;
    facebook.email = email;
    facebook.name = name;
    facebook.link = link;
    
    newState.facebook = facebook;
  }
  if (data.instagram) {
    let { id, email, full_name, link, username } = data.instagram;
    let instagram = {};
    instagram.id = id;
    instagram.email = email;
    instagram.name = full_name;
    instagram.link = link;
    instagram.username = username;

    newState.instagram = instagram;
  }
  if (data.twitter) {
    let { id, displayName } = data.twitter;
    let twitter = {};
    twitter.id = id;
    twitter.displayName = displayName;

    newState.twitter = twitter;
  }
  if (data.youtube) {
    let { id, name } = data.youtube;
    let youtube = {};
    youtube.id = id;
    youtube.name = name;

    newState.youtube = youtube;
  }
  if (data.deezer) {
    let { id, name, lastname, firstname } = data.deezer;
    let deezer = {};
    deezer.id = id;
    deezer.name = name;
    deezer.lastname = lastname;
    deezer.firstname = firstname;

    newState.deezer = deezer;
  }
  return newState;
};

const mapUser = (newState, data) => {
  newState.firstName = data ? data.firstName : null;
  newState.lastName = data ? data.lastName : null;
  newState.artistId = data ? data.artistId : null;
  newState.knktId = data ? data.knktId : null;
  newState.email = data ? data.email : null;
  newState.account = data ? data.account : null;
  newState.street1 = data ? data.street1 : null;
  newState.street2 = data ? data.street2 : null;
  newState.city = data ? data.city : null;
  newState.state = data ? data.state : null;
  newState.zip = data ? data.zip : null;
  newState.company = data ? data.company : null;
  newState.admin = data ? data.admin : null;
  newState.init = data ? data.init : null;
  newState.genre = data ? data.genre : null;
  newState.goal = data ? data.goal : null;
  newState.twitter = data?( data.twitter || {}) : null;
  newState.youtube = data?( data.youtube || {}) : null;
  newState.facebook = data?( data.facebook || {}) : null;
  newState.soundCloud = data?( data.soundCloud || {}) : null;
  newState.instagram = data ? (data.instagram || {}) : null;
  newState.deezer = data ? (data.deezer || {}) : null;
  newState.tier = data ? (data.tier || {}) : null;
  return newState;
};

const mapProfile = (newState, data) => {
  newState.firstName = data ? data.firstName : null;
  newState.lastName = data ? data.lastName : null;
  newState.profileUrl = data ? data.profileUrl : null;
  newState.account = data ? data.account : null;
  newState.email = data ? data.email : null;
  newState.phone = data ? data.phone : null;
  newState.knktId = data ? data.knktId : null;
  return newState;
}

export default user;