import React,{ useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {connect} from 'react-redux';

import {Row, Col} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import {
  updateDashboardMenuIndex,
  updateProfile,
  submitChangePassword,
  resetUpdatePassword } from'../actions/settings/settingsAction';
import SettingMenuList from '../components/pages/settings/SettingMenuList';
import Dashboad from '../components/pages/settings/Dashboard';
import Advertising from '../components/pages/settings/Advertising';

import {getUserProfile} from '../actions/Login/loginAction';

import ChangePassword from '../components/pages/settings/ChangePassword';

import KnktButton from '../components/common/KnktButton';
import Account from '../components/pages/settings/Account';
import Membership from '../components/pages/settings//Membership';
import Notifications from '../components/pages/settings/Notifications';
import Password from '../components/pages/settings/Password';
import Payment from '../components/pages/settings/Payment';
import Team from '../components/pages/settings/Team';

import '../style/settings.css';

const defaultMenuIndex = 1;

// update here for new/existing items
const settingsTabs = [
  {
    title: 'Account',
    isActive: true,
    color: 'knktBlue',
  },
  {
    title: 'Password',
    isActive: false,
    color: 'knktYellow',
  },
  {
    title: 'Membership',
    isActive: false,
    color: 'knktNeon',
  },
  {
    title: 'Get paid',
    isActive: false,
    color: 'knktRed',
  },
  {
    title: 'Team',
    isActive: false,
    color: 'knktBlurple',
  },
  {
    title: 'Notifications',
    isActive: false,
    color: 'knktOcean',
  },
]

const styles = {
  header: {
    minHeight: "30vh",
    background: "#009FFF",
    background:
      "linear-gradient(to right, rgb(67 47 236), rgb(255 165 0),rgb(255 0 0))",
    color: "white",
    /* clipPath: "ellipse(100vw 60vh at 50% 50%)", */
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  details: {
    textAlign: "center",
    color: "#fff",
    marginTop: "-45px",
  },
  profilePic: {
    height: "6rem",
    width: "6rem",
    objectFit: "center",
    borderRadius: "50%",
    border: "2px solid #fff",
  },
  stats: {
    display: "flex",
  },
  statsDetail: {
    width: "10rem",
    textAlign: "center",
  },
  heading: {
    fontWeight: "400",
    fontSize: "1.3rem",
    margin: "1rem 0",
  },
  location: {
    svg: {
      display: "inline-block",
    },
    p: {
      verticalAlign: "middle",
    },
  },
  hr: {
    backgroundColor: "#ffffff",
  },
  button: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  card: {
    padding: "10px",
  },
  cardBg: {
    backgroundColor: "rgb(29 30 30)",
    boxShadow: "rgb(44 44 44) 5px 6px 16px 5px", //"5px 5px 15px 5px #2c2c2c"
    marginBottom: "5vh",
    color: "#FFFFFF",
  },
  bg: {
    backgroundColor: "rgb(26, 29, 36)",
    color: "rgb(255,255,255)",
    margin: "1rem",
  },
  label: {
    display: "inline-block",
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    objectFit: "cover",
    margin: "1.5rem",
  },
  buttonGroup: {
    display: "flex",
    flexWrap: "wrap",
    gap: "12px",
    alignItems: "center",
    justifyContent: "center",
    padding: "0.5rem",
  },
  radio: {
    gap: "12px",
    paddingBottom: "3rem",
  },
};

const SettingsContainerV2 = (routeProps)=> {
  
 
  // useEffect(()=>{
  //   console.log("These are the props in SettingsContainverV2",routeProps);
  // },[])
  
  const dispatch = useDispatch();
  const userProfile = routeProps.profile;
  const [activeTab,setActiveTab] = useState(localStorage.getItem('activeTab') || 'Account');
  useEffect(()=>{
    dispatch(getUserProfile(window.localStorage.getItem('token') || ''));
  },[dispatch]);

  const onClickSettingsTab = (event) => {
    const updatedTabs = settingsTabs.map((tab) => {
      if (tab.title === event.target.value){
        tab.isActive = true;
        setActiveTab(event.target.value);
        localStorage.setItem('activeTab', event.target.value);
      } 
      else tab.isActive = false;
      return tab;
    });
    
    
  };

  let renderActiveTab = () => {
    switch(activeTab){
      case 'Account':
        return <Account props={routeProps} styleProps={styles}/>;
      case 'Password':
        return <Password props={routeProps}/>
      case 'Membership':
        return <Membership/>;
      case 'Get Paid':
        return <Payment/>;
      case 'Team':
        return <Team props={routeProps}/>;
      case 'Notifications':
        return <Notifications/>;
      default:
        return null;
    }
    
  };

    return (
      <Row className=''>
        <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme)}>
          <Col xs={15}>
            { <div>
              <h4 style={{color: 'white', display: 'flex', justifyContent: 'center'}}>
                {userProfile.firstName + "'s Settings"}
              </h4>
              {/* Navigation Bar */}
              <nav className="settings-navbar">
                <ul className="settings-navbar-list">
                  {settingsTabs.map((tab) => (
                    
                    <li key={tab.title}>
                      <KnktButton  
                      value={
                        tab.title
                                } 
                      type={tab.color}
                      parentCallBack={onClickSettingsTab}
                      isGrouped={true}
                      isActive={tab.isActive}
                      >
                        {tab.title}
                      </KnktButton>
                    </li>
                  
                  
                ))}
                </ul>

              </nav>
              
              {/* Content Area */}

              <div className="settings-content my-form">
                {  renderActiveTab() }
              </div>
            </div>
            }
          </Col>
        </MuiThemeProvider>
      </Row>
    )
  };

  function generateProfile(user) {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      profileUrl: '',
      account: user.account,
      email: user.email,
      phone: user.phone,
      knktId: user.knktId,
      updateSuccessful: user.updateSuccessful,
      updateError: user.updateError
    }
  };

function mapStateToProps(state) {
  return {
    user: state.user,
    profile: generateProfile(state.user),
    dashboardMenuIndex: state.site.dashboardMenuIndex || defaultMenuIndex,
    settings: state.settings
  };
};

function mapDispatchToProps(dispatch) {
  return {
    updateDashboardMenuIndex: (index) => dispatch(updateDashboardMenuIndex(index)),
    getUserProfile: (token) => dispatch(getUserProfile(token)),
    updateProfile: (profile) => dispatch(updateProfile(profile)),
    submitChangePassword: ({newPassword, confirmNewPassword}) => dispatch(submitChangePassword({newPassword, confirmNewPassword})),
    resetUpdatePassword: () => dispatch(resetUpdatePassword())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainerV2);