import { callApi } from "./utils";

const updateGenre = async (values) => {
  return await callApi('/api/v1/updateProfile', 'post', {
    genres: values
  });
}

const updateGoal = async (values) => {
  return await callApi('/api/v1/updateProfile', 'post', {
    goals: values
  });
}

const postImage = async (data) => {
  const { image, name, description, tags, altText } = data;
  const headers = {
    'Content-Type': 'multipart/form-data'
  };

  const formData = new FormData();
  formData.append("image", image);

  return await callApi(`/api/v1/postImage?name=${name}&description=${description}&tags=${tags}&altText=${altText}`, 'post', formData, headers);
}

export {
  updateGenre,
  updateGoal,
  postImage
}