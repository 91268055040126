import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { Button, Table, Card, Badge } from 'react-bootstrap';
import KnktButton from '../../common/KnktButton';
import { convertToMonDDYYYY } from '../../../utils/calendar';

const styles = {
  button: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  cardContainer: {
    padding: '10px',
    marginRight: '10vw',
    marginLeft: '10vw',
  },
  card: {
    textAlign: 'left',
    marginBottom: '4vh',
    marginTop: '1vh',
    backgroundColor: 'rgb(29 30 30)',
    color: '#FFFFFF'
  },
  cardItemContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cardItemDetails: {
    padding: '1rem',
    fontSize: '0.75rem',
  },
  cardHeaderLeftItemName: {
    marginRight: 'auto',
    fontSize: '1.5rem',
  },
  cardHeaderLeftItemOrder: {
    marginRight: 'auto',
    fontSize: '1rem',
  },
  bg: {
    backgroundColor: 'rgb(26, 29, 36)',
    color: 'rgb(255,255,255)',
  },
  buttonTabs: {
    borderRight: '0',
    borderTop: '0',
    borderLeftWidth: '2px',
    borderBottomWidth: '2px',
    color: 'white',
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem',
    transition: 'all 1s',
  },
  table: {
    whiteSpace: 'nowrap',
  },
  badge: {
    lineHeight: 'inherit',
    fontSize: '1rem',
    fontWeight: '200',
  },
  payment: {
    textAlign: 'right',
    fontSize: '1rem',
  },
  amount: {
    textAlign: 'right',
    fontSize: '1.5rem',
  },
  detailsButtonContainer: {
    gap: '1rem',
    display: 'flex',
  },
  detailsButton: {
    color: '#fff',
    borderColor: '#606060',
  },
  paymentBookingBadgeSuccess: {
    fontSize: '1rem',
    fontWeight: '200',
    color: '#fff',
    border: '2px solid #28a745',
    borderTop: '0',
    borderRight: '0',
    marginLeft: '1rem',
    boxShadow: '0px 0px 12px #28a745',
  },
  paymentBookingBadgePending: {
    fontSize: '1rem',
    fontWeight: '200',
    color: '#fff',
    border: '2px solid #ffc107',
    borderTop: '0',
    borderRight: '0',
    marginLeft: '1rem',
    boxShadow: '0px 0px 12px #ffc107',
  },
  bookingBadgeRequested: {
    fontSize: '1rem',
    fontWeight: '200',
    color: '#fff',
    border: '2px solid #007bff',
    borderTop: '0',
    borderRight: '0',
    marginLeft: '1rem',
    boxShadow: '0px 0px 12px #007bff',
  },
  borderHighlight: {
    primary: {
      boxShadow: '0px 0px 20px -1px #007bff',
    },
    warning: {
      boxShadow: '0px 0px 20px -1px #ffc107',
    },
    success: {
      boxShadow: '0px 0px 20px -1px #28a745',
    },
  },
  paymentBookingBadge: {
    fontSize: '1rem',
    fontWeight: '200',
    color: '#fff',
  },
  link: {
    marginRight: '1rem',
  },
};

const getBookingStatus = (status) => {
  switch (status) {
    case 'submitted':
      return 'Upcoming';
    case 'Success':
      return 'Completed';
    case 'INITIAL':
      return 'Requested';
    case 'Cancelled':
      return 'Cancelled';
    default:
      return 'Requested';
  }
};

const BookingHome = (props) => {
  const [bookingTabsTitle, setBookingTabsTitle] = useState([
    {
      title: 'Requested',
      isActive: true,
      color: 'knktBlue',
    },
    {
      title: 'Upcoming',
      isActive: false,
      color: 'knktYellow',
    },
    {
      title: 'Completed',
      isActive: false,
      color: 'knktNeon',
    },
    {
      title: 'Cancelled',
      isActive: false,
      color: 'knktRed',
    },
    {
      title: 'Refunds',
      isActive: false,
      color: 'knktBlurple',
    },
    {
      title: 'All',
      isActive: false,
      color: 'knktOcean',
    },
  ]);
  const [bookingTabSelected, setBookingTabSelected] = useState(
    bookingTabsTitle[0].title
  );
  const [showDetails, setShowDetails] = useState(false);
  const data = [
    {
      orderNo: '121212',
      mediaType: 'Audio',
      name: 'Karan',
      amount: '100',
      date: '12 Nov 2021',
      bookingStatus: 'Completed',
      paymentStatus: 'Pending',
      isDetails: false,
    },
    {
      orderNo: '213132',
      mediaType: 'Video',
      name: 'Vig',
      amount: '150',
      date: '12 Nov 2021',
      bookingStatus: 'Completed',
      paymentStatus: 'Completed',
      isDetails: false,
    },
    {
      orderNo: '45454',
      mediaType: 'Audio',
      name: 'Dino',
      amount: '90',
      date: '12 Nov 2021',
      bookingStatus: 'Requested',
      paymentStatus: 'Pending',
      isDetails: false,
    },
    {
      orderNo: '676575',
      mediaType: 'Video',
      name: 'Stan',
      amount: '175',
      date: '12 Nov 2021',
      bookingStatus: 'Upcoming',
      paymentStatus: 'Completed',
      isDetails: false,
    },
    {
      orderNo: '234243',
      mediaType: 'Audio',
      name: 'Chinmay',
      amount: '160',
      date: '12 Nov 2021',
      bookingStatus: 'Upcoming',
      paymentStatus: 'Pending',
      isDetails: false,
    },
    {
      orderNo: '22113',
      mediaType: 'Audio',
      name: 'Chinmay',
      amount: '100',
      date: '12 May 2022',
      bookingStatus: 'Cancelled',
      paymentStatus: 'Pending',
      isDetails: false,
    },
  ];

  const [stateData, setStateData] = useState(
    data.map((el) => {
      el.date = convertToMonDDYYYY(el.date);
      return el;
    })
  );

  const [bookingData, setBookingData] = useState();

  useEffect(() => {
    if (props.bookingData) {
      setBookingData(
        props.bookingData
          .map((i) => {
            if (i.reqKnktId === props.user.knktId.toString()) {
              return {
                orderNo: i.bookingId,
                mediaType: 'Audio',
                name:
                  i.profile.reqFirstName +
                  ' ' +
                  i.profile.reqLastName,
                amount: '100',
                date: convertToMonDDYYYY(i.createdDate),
                bookingStatus: getBookingStatus(i.status),
                paymentStatus: getBookingStatus(i.payment.status),
                isDetails: false,
              };
            }
          })
          .filter((i) => i)
      );
    }
  }, [props.bookingData]);

  console.log(bookingData);

  const onClickBookingTab = (event) => {
    const updatedTabs = bookingTabsTitle.map((tab) => {
      if (tab.title === event.target.value) tab.isActive = true;
      else tab.isActive = false;
      return tab;
    });
    setBookingTabsTitle(updatedTabs);
    setBookingTabSelected(event.target.value);
  };

  const isArtist = props.user.account === 'artist';

  const getBookingStatusVariant = (bookingStatus) => {
    return bookingStatus === 'Completed'
      ? 'success'
      : bookingStatus === 'Requested' || bookingStatus === 'Pending'
      ? 'primary'
      : 'warning';
  };

  const getBookingStatusStyle = (bookingStatus) => {
    return bookingStatus === 'Completed'
      ? styles.paymentBookingBadgeSuccess
      : bookingStatus === 'Requested' || bookingStatus === 'Pending'
      ? styles.bookingBadgeRequested
      : styles.paymentBookingBadgePending;
  };

  const clickDetails = (index) => {
    const previousShowDetailState = bookingData[index].isDetails;
    setShowDetails(!showDetails);
    setStateData(() => {
      bookingData[index].isDetails = !previousShowDetailState;
      return bookingData;
    });
  };

  const clickRoomLink = () =>{
    const meetingRoomUrl = `https://meeting.knkt.io`
    window.open(meetingRoomUrl, "_blank")

  }

  const RenderCards = () => {
    if (!bookingData) return <></>;
    return (
      <div style={styles.cardContainer}>
        {bookingData.map((bookingCard, index) => {
          if (bookingCard.bookingStatus !== bookingTabSelected) {
            return <></>;
          }
          const paymentBadgeColor =
            bookingCard.paymentStatus === 'Completed'
              ? 'success'
              : 'warning';
          const bookingBadgeColor = getBookingStatusVariant(
            bookingCard.bookingStatus
          );
          const paymentBadgeStyle =
            bookingCard.paymentStatus === 'Completed'
              ? styles.paymentBookingBadgeSuccess
              : styles.paymentBookingBadgePending;
          const bookingBadgeStyle = getBookingStatusStyle(
            bookingCard.bookingStatus
          );
          const mediaTypeBadgeColor =
            bookingCard.mediaType === 'Audio' ? 'primary' : 'danger';
          const mediaIcon =
            bookingCard.mediaType === 'Audio'
              ? 'headphones'
              : 'video';
          const detailsIcon = bookingCard.isDetails
            ? 'down'
            : 'right';

          const cardStyle = {
            ...styles.card,
            ...styles.borderHighlight[bookingBadgeColor],
          };
          return (
            <Card key={bookingCard.orderNo} style={cardStyle}>
              <Card.Header>
                <div style={styles.cardItemContainer}>
                  <span style={styles.cardHeaderLeftItemOrder}>
                    {'#' + bookingCard.orderNo}
                  </span>
                  <Badge
                    style={styles.badge}
                    pill
                    variant={mediaTypeBadgeColor}
                  >
                    {bookingCard.mediaType}
                  </Badge>
                </div>
              </Card.Header>
              <Card.Body>
                <Card.Title>
                  <div style={styles.cardItemContainer}>
                    <span style={styles.cardHeaderLeftItemName}>
                      {bookingCard.name}
                    </span>
                    <span style={styles.amount}>
                      {'$' + bookingCard.amount}
                    </span>
                  </div>
                </Card.Title>
                <Card.Text style={styles.cardHeaderLeftItemName}>
                  <span>
                    <i className="far fa-calendar-alt"></i>
                    {' ' + bookingCard.date}
                  </span>
                </Card.Text>

                <div style={styles.detailsButtonContainer}>
                  <Button
                    variant="outline-dark"
                    style={styles.detailsButton}
                    onClick={clickRoomLink}
                  >
                    Room Link{' '}
                    <i className={'fas fa-' + mediaIcon}></i>
                  </Button>
                  <Button
                    variant="outline-dark"
                    style={styles.detailsButton}
                    onClick={() => clickDetails(index)}
                  >
                    Details{' '}
                    <i
                      className={
                        'fas fa-long-arrow-alt-' + detailsIcon
                      }
                    ></i>
                  </Button>{' '}
                </div>
                {bookingCard.isDetails && (
                  <Card.Text style={styles.cardItemDetails}>
                    <span>Details for the card</span>
                  </Card.Text>
                )}
              </Card.Body>
              <Card.Footer>
                <div style={styles.cardItemContainer}>
                  <span style={styles.payment}>
                    Payment{' '}
                    <Badge
                      style={paymentBadgeStyle}
                      bg={paymentBadgeColor}
                    >
                      {bookingCard.paymentStatus}
                    </Badge>
                  </span>
                </div>
              </Card.Footer>
            </Card>
          );
        })}
      </div>
    );
  };
  return (
    <div>
      <section>
        <div style={styles.buttonGroup}>
          {bookingTabsTitle.map((bookingTabs) => (
            <KnktButton
              key={bookingTabs.title}
              value={bookingTabs.title}
              type={bookingTabs.color}
              isGrouped={true}
              isActive={bookingTabs.isActive}
              parentCallBack={onClickBookingTab}
            />
          ))}
        </div>
      </section>
      {isArtist && bookingTabSelected === 'Requested' && (
        <RenderCards />
      )}
      {isArtist && bookingTabSelected === 'Upcoming' && (
        <RenderCards />
      )}
      {isArtist && bookingTabSelected === 'Completed' && (
        <RenderCards />
      )}
      {isArtist && bookingTabSelected === 'Cancelled' && (
        <RenderCards />
      )}
    </div>
  );
};

export default BookingHome;
