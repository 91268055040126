import React, { Fragment, useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Button } from 'react-bootstrap';
import ArtistHome from '../components/pages/artists/ArtistHome';
import { getApiUrl } from '../config/siteConfig';

const ArtistHomeContainer =  (props) =>{
    const [artistData, setArtistData] = useState()

    const styles = {
        parentContainer: {
            height: "100%", color: "#fff", textAlign: "center"
        }
    };

    const getAllArtistsData = () =>{
        axios({
            url: getApiUrl() + '/api/v1/artists',
            method: 'get',
            headers: {
              token: window.localStorage.getItem('token') || ''
            }
          }).then((res) => {
            setArtistData(res.data)
            
          }).catch((err) => {
            console.log('artist api error: ', err);
          })
    }

    useEffect(()=>{
        getAllArtistsData()
    },[])

    if(artistData == null){
      return <>Loading..</>
    }

    return(
    <Row style={styles.parentContainer}>
       <Col xs="12"><ArtistHome user={props.user} artists={artistData.artists}/></Col>
    </Row>
    );
}

export default ArtistHomeContainer;