import React, { Component, useState } from 'react';
import { Row, Col, Button, Card, Image } from 'react-bootstrap';
import SearchBar from '../../../components/common/SearchBar';
import history from '../../../redux/manageHistory';
import Radium from 'radium';

const ArtistHome = (props) => {

    const { artists } = props

    const [content, setContent] = useState([]);
    const [isFiltered, setIsFiltered] = useState(false);
    const [isHover, setIsHover] = useState(false)
    const [hoverCard, sethoverCard] = useState('')

    const styles = {
        searchBarRow: {
            margin: '1rem 0',
        },
        socialParent: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignContent: 'center',
            flexWrap: 'wrap',
        },
        socialContainer: {
            borderRadius: '1rem',
            height: 217,
            background: '#2c2c2c',
            margin: 15,
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            gap: '3px',
        },
        connectText: {
            fontSize: '16px',
        },
        hover: {
            tiktok: {
                ':hover': {
                    boxShadow: '0px 0px 15px 6px #e5004e',
                    background: '#0000',
                    cursor: 'pointer',
                },
            },
        }
    }

    const onHoverIn = (e) => {
        // console.log(e.target.id, "mouse enter");
        setIsHover(true)
        sethoverCard(e.target.id)
    };

    const onHoverOut = (e) => {
        // console.log(e.target.id, "mouse exit");
        setIsHover(false)
        sethoverCard('')
    };

    const onClickProfileDiv = (e, { profileName, place, knktId }) => {
        console.log(profileName, place, knktId)
        history.push({
            pathname: '/profile',
            state: { profileName, place, knktId }
        });
    }

    useState(() => {
        if (artists != null) {
            artists.names.length > 0 && setContent(artists.names)
        }
    }, [artists])
   

    if (artists == null) {
        return (<>Loading....</>)
    }

    return (
        <div style={{ margin: '1rem' }}>
            <Row style={styles.searchBarRow}>
                <Col xs="12">
                    <SearchBar
                        data={[{ knktId: 1, artistName: 'Armout Van Buuren' }, { knktId: 2, artistName: 'Armin Van Buuren' }]}
                        getResult={(result) => {}}
                        isFiltered={(isFiltered) =>
                            setIsFiltered(isFiltered)}
                    />
                </Col>
            </Row>
            <Row nogutters="true" style={styles.socialParent}>
                <Col xs={6} sm={4} md={3}>
                    <div
                        id="atrak"
                        key={'atrak'}
                        value={'A-Trak'}
                        style={[styles.socialContainer, styles.hover.tiktok]}
                        onMouseEnter={onHoverIn}
                        onMouseLeave={onHoverOut}
                        onClick={(e) => onClickProfileDiv(e, { profileName: 'A-Trak', place: 'New York, US', knktId: '' })}
                    >
                        <i class="fab fa-tikok fa-3x brand-opacity"></i>
                        <h4 className="text-uppercase">A-Trak</h4>
                        <p style={styles.connectText}>
                            {isHover && hoverCard === 'atrak' ? 'View Profile' : ''}
                        </p>
                    </div>

                </Col>
                <Col xs={6} sm={4} md={3}>
                    <div
                        id="armin"
                        key={'armin'}
                        style={[styles.socialContainer, styles.hover.tiktok]}
                        onMouseEnter={onHoverIn}
                        onMouseLeave={onHoverOut}
                        onClick={(e) => onClickProfileDiv(e, { profileName: 'Armin Van Buuren', place: 'Amsterdam, NL', knktId: '' })}
                    >
                        <i class="fab fa-tikok fa-3x brand-opacity"></i>
                        <h4 className="text-uppercase">Armin Van Buuren</h4>
                        <p style={styles.connectText}>
                            {isHover && hoverCard === 'armin' ? 'View Profile' : ''}
                        </p>
                    </div>
                </Col>
                <Col xs={6} sm={4} md={3}>
                    <div
                        id="mmaya"
                        key={'mmaya'}
                        style={[styles.socialContainer, styles.hover.tiktok]}
                        onMouseEnter={onHoverIn}
                        onMouseLeave={onHoverOut}
                        onClick={(e) => onClickProfileDiv(e, { profileName: 'Moh Maya', place: 'San Francisco, US', knktId: '' })}
                    >
                        <i class="fab fa-tikok fa-3x brand-opacity"></i>
                        <h4 className="text-uppercase">Moh Maya</h4>
                        <p style={styles.connectText}>
                            {isHover && hoverCard === 'mmaya' ? 'View Profile' : ''}
                        </p>
                    </div>
                </Col>
                <Col xs={6} sm={4} md={3}>
                    <div
                        id="eprydz"
                        key={'eprydz'}
                        style={[styles.socialContainer, styles.hover.tiktok]}
                        onMouseEnter={onHoverIn}
                        onMouseLeave={onHoverOut}
                        onClick={(e) => onClickProfileDiv(e, { profileName: 'Eric Prydz', place: 'Stockholm, SW', knktId: '' })}
                    >
                        <i class="fab fa-tikok fa-3x brand-opacity"></i>
                        <h4 className="text-uppercase">Eric Prydz</h4>
                        <p style={styles.connectText}>
                            {isHover && hoverCard === 'eprydz' ? 'View Profile' : ''}
                        </p>
                    </div>
                </Col>
                <Col xs={6} sm={4} md={3}>
                    <div
                        id="dixon"
                        key={'dixon'}
                        style={[styles.socialContainer, styles.hover.tiktok]}
                        onMouseEnter={onHoverIn}
                        onMouseLeave={onHoverOut}
                        onClick={(e) => onClickProfileDiv(e, { profileName: 'Dixon', place: 'Berlin, DE', knktId: '' })}
                    >
                        <i class="fab fa-tikok fa-3x brand-opacity"></i>
                        <h4 className="text-uppercase">Dixon</h4>
                        <p style={styles.connectText}>
                            {isHover && hoverCard === 'dixon' ? 'View Profile' : ''}
                        </p>
                    </div>
                </Col>
                {content.map((aritst, index) => (
                    <Col xs={6} sm={4} md={3}>
                        <div
                            id={aritst.name}
                            key={index}
                            style={[styles.socialContainer, styles.hover.tiktok]}
                            onMouseEnter={onHoverIn}
                            onMouseLeave={onHoverOut}
                            onClick={(e) => onClickProfileDiv(e, { profileName: aritst.name, place: 'NA', knktId: aritst.knktId })}
                        >
                            <i class="fab fa-tikok fa-3x brand-opacity"></i>
                            <h4 className="text-uppercase">{aritst.name}</h4>
                            <p style={styles.connectText}>
                                {isHover && hoverCard === aritst.name ? 'View Profile' : ''}
                            </p>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    )
};

export default Radium(ArtistHome);